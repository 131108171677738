import React, { useEffect, useState } from "react";

import { useStateContext } from '../contexts/ContextProvider';
import axios from "axios";

import './AllGPs.css';
import { ReactComponent as Clock } from "../assets/clock-regular.svg";
import { ReactComponent as POTD } from "../assets/POTD.svg";

const AllGPs = () => {

    const { allGPs, setAllGPs } = useStateContext();
    const [openedGPs, setOpenedGPs] = useState([])

    const changeOpenedGPs = (gp) => {
        if (openedGPs.includes(gp.circuito)) {
            setOpenedGPs(openedGPs.filter(g => g !== gp.circuito))
        } else {
            setOpenedGPs([...openedGPs, gp.circuito])
        }
    }

    const getGp = () => {
        axios.get(`${process.env.REACT_APP_BACK_URL}allGPs`)
        .then((response) => {
            setAllGPs(response.data);
        })
        .catch((error) => {
            return;
        });
    }

    useEffect(() => {
        getGp();
    }, []);

    return (
        <div className='allGPs'>
            {
                allGPs.length > 0 && allGPs.map((gp, index) => {
                    return (
                        <div key={index} className='gp'>
                            <div className="gpTitle" style={{backgroundColor: openedGPs.includes(gp.circuito) ? 'white' : 'grey'}} onClick={() => {changeOpenedGPs(gp)}}>
                                <h2 className="jornada">{gp.jornada}</h2>
                                <h2 className="pais">{gp.pais}</h2>
                            </div>
                            { openedGPs.includes(gp.circuito) && <div className='gpMap'>
                                <img src={gp.mapa} alt={gp.circuito} />
                            </div> }
                            { openedGPs.includes(gp.circuito) && <div className='gpInfo'>
                                <div style={{border: '1px solid white', backgroundColor: '#e6e6e6'}}><strong>CIRCUITO:</strong> {gp.circuito}</div>
                                <div style={{border: '1px solid white', backgroundColor: '#f2f2f2'}}><strong>FECHA:</strong> {gp.fecha}</div>
                                <div style={{border: '1px solid white', backgroundColor: '#e6e6e6'}}><strong>LONGITUD:</strong> {gp.longitud} metros</div>
                                <div style={{border: '1px solid white', backgroundColor: '#f2f2f2'}}><strong>CURVAS:</strong> {gp.curvas.izquierda + gp.curvas.derecha} ({gp.curvas.izquierda} izda | {gp.curvas.derecha} dcha)</div>
                            </div> }
                            { openedGPs.includes(gp.circuito) && <div className='gpPositions'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{minWidth: 5, width: 5}}></th>
                                            <th style={{textAlign: 'left', paddingLeft: 10}}>Piloto</th>
                                            <th style={{textAlign: 'left', paddingLeft: 10}}></th>
                                            <th>Paradas</th>
                                            <th>Parrilla</th>
                                            <th style={{textAlign: 'right', paddingRight: 10}}>Hotlap</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(gp.pilotos).map((piloto, index) => {
                                            const color = () => {
                                                if (index === 0) {
                                                    return 'gold';
                                                } else if (index === 1) {
                                                    return 'silver';
                                                } else if (index === 2) {
                                                    return '#CD7F32';
                                                } else if (index === Object.keys(gp.pilotos).length - 1) {
                                                    return 'red';
                                                } else {
                                                    return 'black';
                                                }
                                            }
                                            return (
                                                <tr className='parrilla' key={index} style={{backgroundColor: index % 2 === 0 ? '#e6e6e6' : '#f2f2f2'}}>
                                                    <td className='position' style={{backgroundColor: color()}}></td>
                                                    <td style={{textAlign: 'left', paddingLeft: 10}}>{gp.pilotos[piloto].nombre}</td>
                                                    <td style={{textAlign: 'left', paddingLeft: 10}}>{gp.pilotos[piloto].posicion.includes("DNF") ? 'DNF' : ''}{gp.pilotos[piloto].isPOTD ? <POTD className="hotlap-icon" style={{fill: 'yellow', color: 'yellow'}} /> : ''}</td>
                                                    <td>{gp.pilotos[piloto].paradas}</td>
                                                    <td>{gp.pilotos[piloto].parrilla}</td>
                                                    <td style={{color: gp.pilotos[piloto].isHotlap ? 'purple' : 'black', textAlign: 'right', paddingRight: 10}}>{gp.pilotos[piloto].hotlap}</td>
                                                    <td className='time' style={{textAlign: 'left', paddingLeft: 10}}>{gp.pilotos[piloto].isHotlap ? <Clock className="hotlap-icon" style={{fill: 'purple'}} /> : ''}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AllGPs