import React from "react";

import Clasificacion from "../components/Clasificacion";
import AllGPs from "../components/AllGPs";

import '../style/Home.css';

const Home = () => {

  return (
    <div className='home'>
      <Clasificacion />
      <AllGPs />
    </div>
  )
}

export default Home