import React, { useEffect } from "react";

import { useStateContext } from '../contexts/ContextProvider';
import axios from "axios";

import './Clasificacion.css';

const Clasificacion = () => {

    const { clasificacion, setClasificacion } = useStateContext();

    const getClasificacion = () => {
        axios.get(`${process.env.REACT_APP_BACK_URL}clasificacion`)
        .then((response) => {
            const ordenado = Object.fromEntries(
                Object.entries(response.data).sort((a, b) => b[1] - a[1])
            );
            setClasificacion(ordenado);
        })
        .catch((error) => {
            return;
        });
    }

    useEffect(() => {
        getClasificacion();
    }, []);

    return (
        <div className='clasificacion'>
            <h1>CLASIFICACIÓN</h1>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th style={{textAlign: 'left', paddingLeft: 10}}>Posición</th>
                        <th>Piloto</th>
                        <th>Puntos</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(clasificacion).length > 0 && Object.keys(clasificacion).map((piloto, index) => {
                        const color = () => {
                            if (index === 0) {
                                return 'gold';
                            } else if (index === 1) {
                                return 'silver';
                            } else if (index === 2) {
                                return '#CD7F32';
                            } else if (index === Object.keys(clasificacion).length - 1) {
                                return 'red';
                            } else {
                                return 'black';
                            }
                        }
                        return (
                            <tr key={index} style={{border: '2px solid white', backgroundColor: index % 2 === 0 ? '#e6e6e6' : '#f2f2f2'}}>
                                <td className='position' style={{backgroundColor: color()}}></td>
                                <td style={{textAlign: 'left', paddingLeft: 35}}>{index + 1}</td>
                                <td>{piloto}</td>
                                <td>{clasificacion[piloto]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Clasificacion;