import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {

    const [clasificacion, setClasificacion] = useState({});
    const [allGPs, setAllGPs] = useState({});
    
    return (
        <StateContext.Provider value={{ clasificacion, setClasificacion, allGPs, setAllGPs }}>
        {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);